import styled from "@emotion/styled";
import * as React from "react";
import { ToastContainer as ToastContainerUnstyled } from "react-toastify";

const ToastContainer = styled(ToastContainerUnstyled)`
  .Toastify__close-button {
    color: rgba(0, 0, 0, 0.9);
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background: #57e65b;
  }
`;

export default ToastContainer;
