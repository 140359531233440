import * as React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";

import ForestBackgroundImage from "../components/ForestBackgroundImage";
import Main from "../components/Main";
import Layout from "../components/Layout";
import SignUpForm from "./SignUpForm";

import texts from "./texts";

const Heading = styled.h1`
  margin: 0;
  padding: 0 1em;
  font-weight: 400;
  font-size: 2.5em;
  text-shadow: rgba(0, 0, 0, 0.75) 0px 1px 0.2em;
`;

const CallToAction = styled.p`
  margin: 0;
  padding: 0 1em;
  margin-top: 1em;
  font-size: 1.5em;
  font-weight: 300;
  text-shadow: rgba(0, 0, 0, 0.85) 0px 1px 0.35em;
`;

const IndexPage = (props) => {
  const { lang } = props;
  return (
    <Main lang={lang}>
      <Helmet>
        <title>{texts[lang].logoAlt}</title>
        <description>{texts[lang].heading}</description>
      </Helmet>
      <ForestBackgroundImage>
        <Layout lang={lang}>
          <Heading>{texts[lang].heading}</Heading>
          <CallToAction>{texts[lang].callToAction}</CallToAction>
          <SignUpForm lang={lang} />
        </Layout>
      </ForestBackgroundImage>
    </Main>
  );
};

export default IndexPage;
