import * as React from "react";
import styled from "@emotion/styled";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Slide, toast } from "react-toastify";
import Select from "react-select";
import * as EmailValidator from 'email-validator';

import "react-toastify/dist/ReactToastify.min.css";
import texts from "./texts";
import ToastContainer from "./ToastContainer";

const FormWrapper = styled.div`
  margin-top: 2em;
  & > * {
    margin: 0.5em;
  }

  input,
  button,
  select {
    border: none;
    border-radius: 2em;
    padding: 0.5em;
    color: #000;
    text-align: center;
    padding: 0.5em 1em;
    box-shadow: rgba(0, 0, 0, 0.75) 0px 1px 0.2em;
  }

  input:focus {
    outline: none;
    border: none;
  }

  button {
    color: #fff;
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px 2px;
    background-color: #e89f17;
    transition: background-color 0.2s ease-in;
    cursor: pointer;
    padding: 0.75em 1.5em;

    &:hover {
      background-color: #ac7611;
    }
  }

  label {
    display: block;
    margin-left: auto;
    margin-right: auto;

    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px 2px;

    max-width: 95vw;

    @media only screen and (min-width: 480px) {
      max-width: 85vw;
    }
    @media only screen and (min-width: 800px) {
      max-width: 66vw;
    }
    @media only screen and (min-width: 1200px) {
      max-width: 55vw;
    }
    @media only screen and (min-width: 1201px) {
      max-width: 25vw;
    }
  }

  a,
  a:hover,
  a:visited {
    color: #fff;
    /* text-decoration: none; */
  }

  .grecaptcha-badge {
    bottom: 48px;
  }
`;

const EmailAndLanguage = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    margin: 0.5em;
  }
`;

const SignUpForm = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { lang } = props;
  const reCaptchaAction = "newsletterSignUp";
  const subscriptionOrigin = "CPFC Holding Website";

  const [email, setEmail] = React.useState("");
  const [language, setLanguage] = React.useState(lang);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = React.useState(
    false
  );
  const [reCaptchaToken, setReCaptchaToken] = React.useState("");
  const [transmitting, setTransmitting] = React.useState(false);

  const showToast = (text, type = "info") => {
    const config = {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    };

    if (type === "warning") {
      return toast.warn(text, config);
    } else if (type === "error") {
      return toast.error(text, config);
    } else if (type === "success") {
      return toast.success(text, config);
    } else {
      return toast.info(text, config);
    }
  };

  const handleFormSubmit = async (e) => {
    if (typeof e.preventDefault === "function") e.preventDefault();

    // if (!executeRecaptcha || typeof executeRecaptcha !== "function")
    //   return showToast(texts[lang].toasts.reCaptchaInvalid, "warning");

    const reCaptchaResult = await executeRecaptcha(reCaptchaAction);
    setReCaptchaToken(reCaptchaResult);

    if (!email || email === "" || !EmailValidator.validate(email)) {
      return showToast(texts[lang].toasts.noEmailAddress, "warning");
    }
    if (!privacyPolicyAccepted) {
      return showToast(texts[lang].toasts.noPrivacyPolicyAccepted, "warning");
    }
    if (!reCaptchaToken || reCaptchaToken === "") {
      return showToast(texts[lang].toasts.reCaptchaInvalid, "warning");
    }

    const data = JSON.stringify({
      email,
      language,
      privacyPolicyAccepted,
      reCaptchaToken,
      reCaptchaAction,
      subscriptionOrigin,
    });

    setTransmitting(true);

    fetch(`https://subscribe.fzs.org/cpfc/${language}/subscribe.php`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-type": "application/json",
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.success)
          return showToast(texts[lang].toasts.success, "success");
        return showToast(texts[lang].toasts.errorTryAgain, "error");
      })
      .catch((err) => {
        // console.log(err);
        showToast(texts[lang].toasts.errorTryAgain, "error");
      });
    setTransmitting(false);
  };

  const languageOptions = [
    { value: "en", label: texts[lang].english },
    { value: "ua", label: texts[lang].ukrainian },
  ];

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: 150,
      borderRadius: "2em",
      borderColor: state.isSelected ? "none" : "none",
      boxShadow: "rgba(0, 0, 0, 0.75) 0px 1px 0.2em",
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "#000",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#000",
      width: "100%",
      overflow: "initial",
    }),
  };

  return (
    <div>
      <FormWrapper>
        <EmailAndLanguage>
          <input
            placeholder={texts[lang].email}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
          <Select
            options={languageOptions}
            onChange={(e) => setLanguage(e.value)}
            defaultValue={languageOptions[lang === "en" ? 0 : 1]}
            isSearchable={false}
            styles={selectStyles}
          />
        </EmailAndLanguage>
        <label>
          <input
            type="checkbox"
            checked={privacyPolicyAccepted}
            onClick={(e) => setPrivacyPolicyAccepted(e.target.checked)}
          />
          &nbsp;
          {texts[lang].privacyPolicyAccepted}
        </label>
        <GoogleReCaptcha
          onVerify={setReCaptchaToken}
          action={reCaptchaAction}
        />
        <button onClick={handleFormSubmit} disabled={transmitting}>
          {texts[lang].signUp}
        </button>
        <GoogleReCaptcha
          action={reCaptchaAction}
          onVerify={setReCaptchaToken}
        />
      </FormWrapper>
      <ToastContainer transition={Slide} limit={3} style={{ color: "#000" }} />
    </div>
  );
};

export default SignUpForm;
